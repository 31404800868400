import React from 'react';
import Container from './Container';
import { Row, Col } from 'emotion-flex';
import TripleTitle from '../common/TripleTitle';
import presets from '../../styles/presets';
import { SYSTEM_FONTS } from '../../styles/typography';
import { css } from '@emotion/react';
import { ButtonLink } from '../common/Button';

import { ReactComponent as Image1 } from '../../assets/images/effectiveness/outcomes_illustration_1.svg';
import { ReactComponent as Image2 } from '../../assets/images/effectiveness/outcomes_illustration_2.svg';
import { ReactComponent as Image3 } from '../../assets/images/effectiveness/outcomes_illustration_3.svg';
import { ReactComponent as TopBg } from '../../assets/images/effectiveness/img-bg_efficacy-top.svg';
import { ReactComponent as BottomBg } from '../../assets/images/effectiveness/img-bg_efficacy-bottom.svg';

css({
  maxWidth: '100%',
  boxShadow: 'none',
});
function showPercentage(showPercentage: never) {
  if (showPercentage) {
    return <span css={{ fontSize: '36px' }}>%</span>;
  }
}

function getImage(index: number) {
  if (index === 1) {
    return (
      <Image1
        width="192"
        css={{
          maxWidth: '100%',
          display: 'block',
        }}
      />
    );
  } else if (index === 2) {
    return <Image2 width="193" css={{ maxWidth: '100%', display: 'block' }} />;
  } else if (index === 3) {
    return <Image3 width="193" css={{ maxWidth: '100%', display: 'block' }} />;
  }
}

export default function Effectiveness({
  prefix,
  title,
  text,
  items,
  callToAction,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) {
  return (
    <>
      <TopBg
        width="100%"
        height="43"
        css={{ display: 'block', width: '100%', height: '43px' }}
      />

      <div
        css={{
          backgroundColor: '#E8F1F0',
          marginBottom: '-1px',
          marginTop: '-1px',
          paddingBottom: '50px',
          paddingTop: '50px',
        }}
      >
        <Container>
          <Row>
            <Col sm={10} smOffset={1} md={6} mdOffset={3}>
              <TripleTitle
                labelText={prefix}
                headingText={title}
                paragraphText={text}
              />
            </Col>
          </Row>

          <Row css={{ marginTop: '25px' }}>
            <Col>
              {items &&
                items.map(
                  (item: {
                    id: React.Key;
                    image: number;
                    bigNumber: React.ReactNode;
                    showPercentage: never;
                    smallNumber: React.ReactNode;
                    text: React.ReactNode;
                  }) => (
                    <div
                      key={item.id}
                      css={{
                        backgroundColor: '#D7E8E7',
                        borderRadius: '10px',
                        width: '690px',
                        maxWidth: '100%',
                        margin: '15px auto 0',
                        padding: '5px 20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',

                        [presets.sm]: {
                          flexDirection: 'row',
                        },
                      }}
                    >
                      <div
                        css={{
                          overflow: 'hidden',
                          display: 'inline-block',
                          marginTop: '10px',
                        }}
                      >
                        {getImage(item.image)}
                      </div>

                      <div
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignContent: 'center',
                          flexShrink: 0,
                          textAlign: 'center',
                          fontFamily: ['Recoleta Alt']
                            .concat(SYSTEM_FONTS)
                            .join(', '),
                          fontWeight: 600,
                          color: '#000',
                          marginTop: '13px',
                          marginBottom: '13px',
                          [presets.sm]: { marginTop: '0', marginBottom: '0' },
                        }}
                      >
                        <div
                          css={{
                            fontSize: '48px',
                            lineHeight: '37px',
                            [presets.lg]: {
                              fontSize: '48px',
                              lineHeight: '37px',
                            },
                          }}
                        >
                          {item.bigNumber}
                          {showPercentage(item.showPercentage)}
                        </div>
                        <div
                          css={{
                            fontSize: '18px',
                            lineHeight: '17px',
                            [presets.lg]: {
                              fontSize: '24px',
                              lineHeight: '20px',
                            },
                          }}
                        >
                          {item.smallNumber}
                        </div>
                      </div>

                      <div css={{ flexBasis: '100%' }}>
                        <p
                          css={{
                            marginBottom: 0,
                            color: '#000',
                            opacity: 0.8,
                            fontSize: '0.875rem',
                            fontFamily: ['Open Sans']
                              .concat(SYSTEM_FONTS)
                              .join(', '),
                            textAlign: 'center',
                            marginTop: '10px',
                            [presets.sm]: {
                              paddingLeft: '79px',
                              textAlign: 'left',
                            },
                            [presets.lg]: { fontSize: '18px' },
                          }}
                        >
                          {item.text}
                        </p>
                      </div>
                    </div>
                  )
                )}
            </Col>
          </Row>

          <Row>
            <Col css={{ textAlign: 'center', paddingTop: '45px' }}>
              <ButtonLink to={callToAction.url}>{callToAction.text}</ButtonLink>
            </Col>
          </Row>
        </Container>
      </div>

      <BottomBg
        width="100%"
        height="43"
        css={{ display: 'block', width: '100%', height: '43px' }}
      />
    </>
  );
}
